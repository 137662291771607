<template>
    <v-card width="100%">
        <v-tabs v-model="tab">
            <v-tab>Evidences</v-tab>
            <v-tab @click="switchToLog()">Profile Histories</v-tab>
            <v-tab @click="switchToEquipment();">Equipments</v-tab>
            <v-tab @click="switchToEvaluation();">Evaluation</v-tab>

        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card>

                    <v-container fluid>
                        <v-row dense>
                        <v-col
                            v-for="(img, index) in allEvidences" :key="`img-${index}`"
                            cols="3"
                        >
                            <v-card>
                            <v-img
                                :src="img.src"
                                class="white--text align-end"
                                height="200px"
                                @click="showEnlargeImg(index)"
                            >
                            </v-img>
                            <v-card-actions>
                            <v-card-text class="text--primary">
                                <div>{{img.title}}</div>
                            </v-card-text>
                                <v-spacer></v-spacer>
                                <uploadImage :img="img"/>
                                <v-btn icon>
                                    <v-icon @click="showEnlargeImg(index)">mdi-magnify-plus</v-icon>
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-col>
                        </v-row>
                        <!-- Popup Large Images -->
                        <v-dialog
                            v-model="imgEnlargeModal"
                            max-width="1200px"
                            scrollable
                        >
                            <v-card>
                                <v-card-title>
                                    <span>{{ enlargeImage.title }}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon text @click="imgEnlargeModal = false"><v-icon>mdi-close</v-icon></v-btn>
                                </v-card-title>
                                <v-img
                                    :src="enlargeImage.src"
                                    class="white--text align-end"
                                    height="auto"
                                >
                                </v-img>
                            </v-card>
                        </v-dialog>
                    </v-container>



                </v-card>
            </v-tab-item>
            <v-tab-item>  
                <v-card>
                    <v-container fluid>
                        <template>
                            <v-data-table
                                :headers="log_headers"
                                :items="logs"
                            >
                                <template v-slot:item.updated_at="{ item }">
                                    <span>{{ item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss') : "---" }}</span>
                                </template>
                                <template v-slot:item.action_type="{ item }">
                                    <span>{{ item.action_types.join(", ") }}</span>
                                </template>
                                <template v-slot:item.remark="{ item }">
                                    <span>{{ item.action_url === "PartnerDeleteCallback" ? item.deletion_remark : (item.remark || '---') }}</span>
                                </template>

                            </v-data-table>
                        </template>
                    </v-container>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card>
                    <v-container fluid>
                        <template>
                            <v-simple-table v-if="is_load_equipment" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                ID
                                            </th>
                                            <th class="text-center">
                                                
                                            </th>
                                            <th class="text-center">
                                                Name
                                            </th>
                                            <th class="text-center">
                                                Payment
                                            </th>
                                            <th class="text-center">
                                                Transaction
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in equipmentListTransform"
                                            :key="'eq_' + item.id"
                                            :class="{ 'teal lighten-4': item.is_own }"
                                        >
                                            <td class="text-center">{{ item.id }}</td>
                                            <td class="text-center">
                                                    <v-checkbox :key="'eq_check_' + item.id"
                                                        v-model="item.is_checked"
                                                        @change="reloadTable()"
                                                    ></v-checkbox>
                                            </td>
                                            <td class="text-center">{{ item.sku_no + item.name }} <p style="color: gray; white-space: pre-line;">{{ item.description }}</p></td>
                                            <td class="">
                                                <div v-if="item.amount == 0">
                                                    Free
                                                </div>
                                                <div v-else-if="item.amount > 0 && Array.isArray(item.equipment_payment_profile)">
                                                    <div v-if="!item.is_own && item.is_checked">
                                                        <v-radio-group
                                                            v-model="item.new_payment_method"
                                                            column
                                                            @change="reloadTable()"
                                                        >
                                                            <v-radio v-if="item.is_full_payment"
                                                                label="Full payment"
                                                                value="full"
                                                            ></v-radio>
                                                            <v-radio v-if="item.is_installment"
                                                                :label="'Installment, ' + item.installment_per_period  + ' /' + item.installment_period "
                                                                value="installment"
                                                            ></v-radio>
                                                        </v-radio-group>
                                                        <v-select v-if="item.new_payment_method == 'installment'"
                                                            v-model="item.new_payment_installment_profile_id"
                                                            :items="item.installment_down"
                                                            item-text="amount_down"
                                                            item-value="id"
                                                            label="Down payment"
                                                            dense
                                                        ></v-select>
                                                        <v-file-input v-if="item.new_payment_method"
                                                            show-size
                                                            counter
                                                            dense
                                                            label="Payment evidence"
                                                            v-model="item.new_payment_evidence"
                                                        ></v-file-input>
                                                    </div>
                                                    <div v-else-if="!item.is_own && !item.is_checked">
                                                        <ul>
                                                            <li v-if="item.is_full_payment">Full payment</li>
                                                            <li v-if="item.is_installment">Installment, {{ item.installment_per_period }} / {{ item.equipment_payment_profile[0] ? item.equipment_payment_profile[0].installment_period : '' }},<br>with down payment {{ item.installment_down.map((i) => i.amount_down).join(", ") }} Baht</li>
                                                        </ul>
                                                    </div>
                                                    <div v-else>
                                                        <div v-if="item.employee_equipment.amount > item.employee_equipment.amount_down + item.employee_equipment.amount_paid_installment">
                                                            <div><b>Amount :: </b>{{ item.employee_equipment.amount }}</div>
                                                            <div><b>Down payment :: </b>{{ item.employee_equipment.amount_down }}</div>
                                                            <div><b>Paid by installment :: </b>{{ item.employee_equipment.amount_only_installment }}</div>
                                                            <div><b>Last paid :: </b>{{ item.employee_equipment.last_paid_at ? moment(item.employee_equipment.last_paid_at).format("YYYY-MM-DD HH:mm:ss") : "Never paid" }}</div>
                                                        </div>
                                                        <div v-else>
                                                            Complete Payment
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                                <div v-if="item.employee_equipment && item.employee_equipment.delivery_note != ''">
                                                    <b>Note ::</b> {{ item.employee_equipment.delivery_note }}
                                                </div>

                                                <div v-if="!item.is_own && item.is_checked" :key="'eq_inp_note_' + item.id">
                                                    <v-text-field
                                                        label="Note :: "
                                                        filled
                                                        dense
                                                        v-model="item.delivery_note"
                                                    ></v-text-field>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div v-if="item.amount > 0 && item.is_own">
                                                    <v-btn 
                                                        v-if="canDo && canDo.can.includes('update')" x-small
                                                        class="mx-1 px-1" depressed dark color="primary" @click="loadEquipmentTransModal(item)">
                                                        View
                                                    </v-btn>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-btn 
                                v-if="canDo && canDo.can.includes('update')"
                                :disabled="!isActive"
                                class="mx-1 px-8" depressed dark color="primary" v-show="!savingState" @click="saveEmployeeEquipment()">
                                Save
                            </v-btn>
                            <v-dialog
                                v-model="is_show_equipment_trans_modal"
                                max-width="1600px"
                                width="90%"
                                
                                >
                                <v-card v-if="sel_equipment">
                                    <v-card-title>{{ sel_equipment.sku_no }} {{ sel_equipment.name }}</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text >
                                        <v-simple-table height="70%" dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">
                                                            Transaction Date
                                                        </th>
                                                        <th class="text-center">
                                                            Term No
                                                        </th>
                                                        <th class="text-center">
                                                            Amount
                                                        </th>
                                                        <th class="text-center">
                                                            Remain
                                                        </th>
                                                        <th class="text-center">
                                                            Source
                                                        </th>
                                                        <th class="text-center">
                                                            Evidence
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                    v-for="(item) in sel_equipment_trans"
                                                    :key="item.id"
                                                    >
                                                        <td class="text-center">{{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</td>
                                                        <td class="text-center">
                                                            <span v-if="item.term_no > 0">{{ item.term_no }}</span>
                                                            <span v-else>Down</span>
                                                        </td>
                                                        <td class="text-center">{{ item.amount }}</td>
                                                        <td class="text-center">{{ item.remain_amount }}</td>
                                                        <td class="text-center">{{ item.deduct_source }}</td>
                                                        <td class="text-center">
                                                            <a :href="item.evidence.down_payment_evidence.download_link" v-if="item.evidence.down_payment_evidence">
                                                                Download
                                                            </a>
                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="is_show_equipment_trans_modal = false"
                                    >
                                        Close
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                        </template>
                    </v-container>
                </v-card>
            </v-tab-item>
            <v-tab-item>  
                <v-card>
                    <v-container fluid>
                        <template>
                            <v-data-table
                                :headers="evaluation_headers"
                                :items="evaluations"
                            >
                                <template v-slot:item.evaluation_date="{ item }">
                                    <span>{{ item.evaluation_date ? moment(item.date).format('YYYY-MM-DD HH:mm:ss') : "---" }}</span>
                                </template>

                            </v-data-table>
                        </template>
                    </v-container>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import uploadImage from '@/components/drivers/upload_image.vue';
import _ from 'lodash';
import helpers from '@/helpers';

export default {
    name: 'LeadApproval',
    components: {
        uploadImage
    },
    props: {
        'driverid': Number
    },
    async created() {
        await this.genCan();
        await this.getEquipments();
        this.equipmentListTransform = _.cloneDeep(this.equipmentList);
        
    },
    data: () => ({
        imgEnlargeModal: false,
        enlargeImage : {},
        tab: null,
        is_load_log : false,
        is_load_equipment : false,
        logs: [],
        evaluations: [],
        log_headers: [
            { text: "No.", value: "idx", sortable: false },
            { text: "Action Type", value: "action_type", sortable: false },
            { text: "Action Date", value: "updated_at", sortable: false },
            { text: "Action By", value: "updated_by_email", sortable: false },
            { text: "Action URL", value: "action_url", sortable: false },
            { text: "Remark", value: "remark", sortable: false },
        ],
        evaluation_headers: [
            { text: "No.", value: "id", sortable: false },
            { text: "Category", value: "category", sortable: false },
            { text: "Subcategory", value: "subcategory", sortable: false },
            { text: "Title", value: "title", sortable: false },
            { text: "Position", value: "position", sortable: false },
            { text: "Region", value: "region", sortable: false },
            { text: "Total", value: "total_num", sortable: false },
            { text: "Correct", value: "success_num", sortable: false },
            { text: "Incorrect", value: "fail_num", sortable: false },
            { text: "Status", value: "exam_status", sortable: false },
            { text: "Date", value: "evaluation_date", sortable: false },
        ],
        evaluation: [],
        equipmentListTransform: [],
        is_show_equipment_trans_modal : false,
        sel_equipment : null,
        sel_equipment_trans : [],
        isActive: true
    }),
    methods: {
        async loadEquipmentTransModal(equipment){
            console.log("equipment", equipment);
            this.sel_equipment = _.cloneDeep(equipment);
            //load trans log
            this.sel_equipment_trans = await this.listEmployeeEquipmentTransaction({
                employee_id : this.driverid, 
                employee_equipment_id : equipment.employee_equipment.id,
                equipment_amount : equipment.employee_equipment.amount
            });
            // console.log(this.sel_equipment_trans)
            this.is_show_equipment_trans_modal = true;
        },
        showEnlargeImg(index){
            this.enlargeImage = this.allEvidences[index];
            this.imgEnlargeModal = true
        },
        async switchToLog(){
            if (this.is_load_log == false)
            {
                this.logs = await this.getDriverChangeLog({ id: this.driverid });           
                this.is_load_log = true
            }
        },
        async switchToEvaluation(){
            if(this.DriverDetails?.employee_evaluation){
                this.evaluations = this.DriverDetails.employee_evaluation;
            }
        },
        async switchToEquipment(){
            this.isActive = true;
            if (this.is_load_equipment == false)
            {
                await this.getEmployeeEquipments({ id : this.driverid });

                //transform equipment list
                this.equipmentListTransform = _.cloneDeep(this.equipmentList);
                for (let i = 0 ; i < this.equipmentListTransform.length ; i++ )
                {
                    let emp_eq = this.employeeEquipment.find( (e) => { return e.equipment_id == this.equipmentListTransform[i].id });

                    this.equipmentListTransform[i].new_payment_method = null;
                    this.equipmentListTransform[i].new_payment_installment_profile_id = null;
                    this.equipmentListTransform[i].new_payment_evidence = null;

                    if (!emp_eq)
                    {
                        this.equipmentListTransform[i].is_own = false;
                        this.equipmentListTransform[i].is_checked = false;
                        this.equipmentListTransform[i].delivery_note = "";
                    }
                    else
                    {
                        this.equipmentListTransform[i].is_own = true;
                        this.equipmentListTransform[i].is_checked = true;
                        this.equipmentListTransform[i].delivery_note = emp_eq.delivery_note;
                        emp_eq.amount_only_installment = emp_eq.amount_paid_installment;

                        this.equipmentListTransform[i].employee_equipment = emp_eq;
                    }

                    if ( this.equipmentListTransform[i].amount > 0 && Array.isArray(this.equipmentListTransform[i].equipment_payment_profile))
                    {
                        this.equipmentListTransform[i].is_full_payment = this.equipmentListTransform[i].equipment_payment_profile.reduce( (r, p) => {
                            return r || p.amount == p.amount_down;
                        }, false);
                        this.equipmentListTransform[i].is_installment = this.equipmentListTransform[i].equipment_payment_profile.reduce( (r, p) => {
                            return r || p.amount > p.amount_down;
                        }, false);
                        this.equipmentListTransform[i].installment_per_period = this.equipmentListTransform[i].equipment_payment_profile.reduce( (r, p) => {
                            if (r === false)
                            {
                                if (p.amount > p.amount_down)
                                    return p.installment_term_amount[0];
                            }
                            else
                                return r;
                        }, false);
                        this.equipmentListTransform[i].installment_down = this.equipmentListTransform[i].equipment_payment_profile.reduce( (r, p) => {

                            if (p.amount > p.amount_down)
                            {
                                r.push( { id : p.id, amount_down : p.amount_down } )
                                return r;
                            }
                            return r;
                        }, []);

                    }
                }
                this.is_load_equipment = true;
            }

        },
        async saveEmployeeEquipment() {
            if ( confirm("ยืนยันการเปลี่ยนแปลง Equipment") )
            {
                this.isActive = false;
                for (let i = 0 ; i < this.equipmentListTransform.length ; i++)
                {
                    if (this.equipmentListTransform[i].is_own && !this.equipmentListTransform[i].is_checked)
                    {
                        //delist employee equipment
                        let result = await this.deleteEmployeeEquipments({ 
                            employee_id : this.driverid, 
                            employee_equipment_id : this.equipmentListTransform[i].employee_equipment.id,
                        });
                        console.log(result);
                    }
                    else if (!this.equipmentListTransform[i].is_own && this.equipmentListTransform[i].is_checked)
                    {
                        //add to employee equipment
                        if (this.equipmentListTransform[i].amount == 0)
                        {
                            let result = await this.addEmployeeEquipments({ 
                                employee_id : this.driverid, 
                                equipment_id : this.equipmentListTransform[i].id,
                                delivery_note : this.equipmentListTransform[i].delivery_note,
                            });
                            console.log(result);
                        }
                        else
                        {
                            let equipment_payment_profile_id = null;
                            let profile = null;
                            if (this.equipmentListTransform[i].new_payment_method == "full")
                            {
                                profile = this.equipmentListTransform[i].equipment_payment_profile.find( (p) => {
                                    return p.amount_down == p.amount;
                                })

                                if (profile)
                                    equipment_payment_profile_id = profile.id;
                            }
                            else if (this.equipmentListTransform[i].new_payment_method == "installment")
                            {
                                profile = this.equipmentListTransform[i].equipment_payment_profile.find( (p) => {
                                    return p.id == this.equipmentListTransform[i].new_payment_installment_profile_id;
                                })

                                if (profile)
                                    equipment_payment_profile_id = profile.id;
                            }
                            else
                            {
                                alert("Equipment " + this.equipmentListTransform[i].sku_no + " " + this.equipmentListTransform[i].name + " is invalid payment condition");
                                continue;
                            }

                            if (!equipment_payment_profile_id) {
                                alert("Equipment " + this.equipmentListTransform[i].sku_no + " " + this.equipmentListTransform[i].name + " is invalid payment condition");
                                continue;
                            }

                            console.log(this.equipmentListTransform[i].new_payment_evidence);
                            if (profile && profile.amount_down > 0 && !this.equipmentListTransform[i].new_payment_evidence)
                            {
                                alert("Equipment " + this.equipmentListTransform[i].sku_no + " " + this.equipmentListTransform[i].name + " has no evidence attached.");
                                continue;
                            }

                            let attachments = [];

                            if (this.equipmentListTransform[i].new_payment_evidence)
                            {
                                attachments.push({
                                    slot_name : "down_payment_evidence",
                                    file_name : this.equipmentListTransform[i].new_payment_evidence.name,
                                    file_content : await helpers.toBase64(this.equipmentListTransform[i].new_payment_evidence)
                                });

                            }

                            let result = await this.addEmployeeEquipments({ 
                                employee_id : this.driverid, 
                                equipment_id : this.equipmentListTransform[i].id,
                                delivery_note : this.equipmentListTransform[i].delivery_note,
                                equipment_payment_profile_id : equipment_payment_profile_id,
                            });
                            console.log(result);

                            if (profile.amount_down > 0)
                            {
                                //add transaction for term 0
                                await this.addEmployeeEquipmentTransaction({
                                    employee_id : this.driverid, 
                                    equipment_id : this.equipmentListTransform[i].id,
                                    employee_equipment_id : result.data.id,
                                    paid_amount : profile.amount_down,
                                    attachments : attachments
                                })
                            }
                        }
                    }
                }

                this.is_load_equipment = false;
                this.switchToEquipment();
            }
        },
        async reloadTable() {
            this.equipmentListTransform = [...this.equipmentListTransform ];
        },
        ...mapActions(['getDriverChangeLog', 'getEquipments', 'genCan', 'getEmployeeEquipments', 'addEmployeeEquipments', 'deleteEmployeeEquipments', 'addEmployeeEquipmentTransaction', 'listEmployeeEquipmentTransaction']),

    },
    computed: {
    ...mapGetters(['allEvidences', 'equipmentList', 'canDo','savingState', 'employeeEquipment','DriverDetails']),

  }
}
</script>